export const environment = {
  production: true,
  TAILWIND_MODE: 'build',
  version: '8.5',
  environment: 'prod',
  azureAd: {
    clientId: '91bc9580-8b4a-434a-b026-5a7dfb76be65',
    authority:
      'https://login.microsoftonline.com/1e94adad-8758-4bdb-8010-3a129bb22cbd',
    redirectUri: 'https://jwcptool.com/',
    navigateToLoginRequestUrl: true,
    scopes: ['api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user'],
  },
};
